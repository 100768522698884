import {
  put, call, takeLatest,
} from 'redux-saga/effects';
import { actions as customerActions } from '../slices/customerSlice';
import { ServiceFactory } from '../../services/ServiceFactory';
import { SentryHelper } from '../../helpers/SentryHelper';
import { getHumanErrorFromResponse } from '../../helpers/error';

export function* getCustomer({ payload }) {
  yield put(customerActions.getCustomerRequest());

  try {
    const { decodedToken } = payload;
    const customerService = yield call([ServiceFactory, 'createCustomerService']);
    const customer = yield call([customerService, 'getCustomer']);

    yield put(customerActions.getCustomerSuccess({
      customer: {
        ...customer,
        ...decodedToken.account,
      },
    }));
  } catch (error) {
    SentryHelper.exception({
      e: error,
      tags: {
        fileLocation: 'customerSaga',
        featureFunction: 'getCustomer',
      },
      fingerPrint: ['customer-saga-get-customer'],
    });
    yield put(customerActions.getCustomerFailure({
      error: getHumanErrorFromResponse(error),
    }));
  }
}

export function* enrichRegistration({ payload }) {
  yield put(customerActions.enrichRegistrationRequest());

  try {
    const customerService = yield call([ServiceFactory, 'createCustomerService']);
    const { data } = yield call([customerService, 'enrichRegistration'], payload);

    yield put(customerActions.enrichRegistrationSuccess({
      customer: {
        ...data.customer,
        id: data.account.id,
        email: data.account.email,
      },
      account: {
        id: data.account.id,
        firstName: data.account.firstName,
        lastName: data.account.lastName,
        email: data.account.email,
        newsletterOptIn: data.account.newsletterOptIn,
      },
    }));
  } catch (error) {
    SentryHelper.exception({
      e: error,
      tags: {
        fileLocation: 'customerSaga',
        featureFunction: 'enrichRegistration',
      },
      fingerPrint: ['customer-saga-enrich-registration'],
    });
    yield put(customerActions.enrichRegistrationFailure({
      error: getHumanErrorFromResponse(error),
    }));
  }
}

export function* getAccount({ payload }) {
  yield put(customerActions.getAccountRequest());

  try {
    const { decodedToken } = payload;
    const customerService = yield call([ServiceFactory, 'createCustomerService']);
    const account = yield call([customerService, 'getAccount']);

    yield put(customerActions.getAccountSuccess({
      account: {
        // Add account id from token because it is not returned from service
        id: decodedToken.account.id,
        ...account,
      },
    }));
  } catch (error) {
    SentryHelper.exception({
      e: error,
      tags: {
        fileLocation: 'customerSaga',
        featureFunction: 'getAccount',
      },
      fingerPrint: ['customer-saga-get-account'],
    });
    yield put(customerActions.getAccountFailure({
      error: getHumanErrorFromResponse(error),
    }));
  }
}

export function* updateSurveyDetails({ payload }) {
  yield put(customerActions.updateSurveyDetailsSuccess(payload));
}

export default [
  takeLatest(customerActions.getCustomer, getCustomer),
  takeLatest(customerActions.getAccount, getAccount),
  takeLatest(customerActions.enrichRegistration, enrichRegistration),
  takeLatest(customerActions.updateSurveyDetails, updateSurveyDetails),
];
