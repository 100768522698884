/* eslint-disable max-len */
import React from 'react';

const RebrandingDeco = () => (
  <div className="pd-relative pd-z-10 pd-hidden md:pd-block">
    <figure
      aria-hidden="true"
      className="pd-absolute -pd-left-0 pd-top-[25rem] pd-hidden pd-h-48 pd-w-36 pd-overflow-hidden min-[1550px]:pd-block"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 233.5 192.95"
        className="pd-absolute pd-right-0 pd-top-0 pd-w-[14.5rem] pd-fill-primary-500"
      >
        <path d="M177.9 109.99h-54.46c-.64 0-1.16-.52-1.16-1.16V54.42C122.93 24.56 146.83.67 176.67.01c30.66-.67 56.16 23.73 56.83 54.39-.01 30.65-24.96 55.58-55.6 55.58ZM177.91 1c-.4 0-.81 0-1.21.01-29.31.64-52.77 24.1-53.42 53.41v54.4c0 .09.07.16.16.16h54.46c30.09 0 54.58-24.48 54.6-54.57-.65-29.7-25.03-53.42-54.59-53.42ZM0 137.85c0 30.43 24.67 55.1 55.1 55.1s55.09-24.67 55.1-55.1V83.49c0-.36-.3-.66-.66-.66H55.1C24.7 82.83.05 107.45 0 137.85Z" />
        <circle cx="177.9" cy="159.39" r="10.79" />
      </svg>
    </figure>
    <figure
      aria-hidden="true"
      className="pd-absolute -pd-right-0 pd-top-5 pd-hidden pd-h-[5.25rem] pd-w-[4.5rem] pd-overflow-hidden min-[1400px]:pd-block"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 110.19 110.11"
        className="pd-absolute pd-left-0 pd-top-0 pd-w-[5.25rem] pd-fill-primary-500"
      >
        <path d="M110.19 55.1C110.19 24.67 85.52 0 55.1 0 24.67 0 0 24.67 0 55.1v54.36c0 .36.3.66.66.66H55.1c30.4 0 55.05-24.62 55.1-55.02Z" />
      </svg>
    </figure>
  </div>
);

export default RebrandingDeco;
