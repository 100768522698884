import React from 'react';
import { Experiment, type ExperimentProps, StoreToggle } from '@printdeal/feature-management/react';

type RebrandExperimentProps = {
  fallback?: ExperimentProps['fallback']
  onInitialized?: ExperimentProps['onInitialized'];
  children: React.ReactNode;
};

const RebrandExperiment = ({
  children,
  ...rest
}: RebrandExperimentProps) => (
  <StoreToggle id="drukwerkdeal-nl">
    <StoreToggle.Enabled>
      <Experiment
        id="brand_exp1658_re-branding_2024_v2"
        {...rest}
      >
        <Experiment.Variant>
          {children}
        </Experiment.Variant>
      </Experiment>
    </StoreToggle.Enabled>
  </StoreToggle>
);

export default RebrandExperiment;
