import React, { useContext, useMemo } from 'react';
import {
  FeatureManagementProvider as FeatureManagementProviderComponent,
} from '@printdeal/feature-management/react';
import { useSelector } from 'react-redux';
import { useBreakpoint } from '@printdeal/ui-library';
import { useIntl } from 'react-intl';
import SegmentContext from '../segmentation/SegmentContext';
import { windowPresent } from '../../helpers/window';
import { getStore } from '../../helpers/env';
import { getConfiguration } from '../../helpers/Configuration';

const getTier = () => {
  if (windowPresent) {
    return window.location.hostname === getConfiguration()?.channel?.url ? 'production' : 'development';
  }

  return 'development';
};

interface Props {
  children: React.ReactNode;
}

const FeatureManagementProvider = ({ children }: Props) => {
  const { locale } = useIntl();
  const { accountEmail, isCompany } = useSelector<{
    customer: {
      account: { email: string },
      customer: { custom?: { fields?: { isCompany?: string } } }
    },
  }, {
    accountEmail?: string,
    isCompany?: string,
  }>(({ customer }) => ({
    accountEmail: customer.account.email,
    isCompany: customer.customer?.custom?.fields?.isCompany,
  }));
  const isDesktop = useBreakpoint('lg');
  const { traits } = useContext(SegmentContext);

  const user = useMemo(() => {
    const customerSegmentAttributes = Object.create(null);

    if (traits) {
      if (traits.customerSegmentLevel0) {
        customerSegmentAttributes.customerSegmentLevel0 = traits.customerSegmentLevel0;
      }
      if (traits.customerSegmentLevel1) {
        customerSegmentAttributes.customerSegmentLevel1 = traits.customerSegmentLevel1;
      }
      if (traits.customerSegmentLevel2) {
        customerSegmentAttributes.customerSegmentLevel2 = traits.customerSegmentLevel2;
      }
    }

    return ({
      email: accountEmail || undefined,
      store: getStore(),
      locale,
      custom: {
        device: isDesktop ? 'desktop' : 'mobile',
        isLoggedIn: Boolean(accountEmail),
        isCompany: Boolean(isCompany),
        ...customerSegmentAttributes,
      },
    });
  }, [locale, accountEmail, isCompany, isDesktop, traits]);

  if (!process.env.GATSBY_STATSIG_CLIENT_KEY) {
    throw new Error('Environment variable "GATSBY_STATSIG_CLIENT_KEY" is not set.');
  }

  return (
    <FeatureManagementProviderComponent
      user={user}
      apiKey={process.env.GATSBY_STATSIG_CLIENT_KEY}
      environment={getTier()}
    >
      {children}
    </FeatureManagementProviderComponent>
  );
};

export default FeatureManagementProvider;
