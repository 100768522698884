export const STORES = {
  drukwerkdeal: 'drukwerkdeal-nl',
  printdeal: 'printdeal-be',
  impressto: 'impressto-fr',
};

export const ALGOLIA_TYPES = {
  PDP_INDEX: 'ProductPages',
  PROD_CAT_INDEX: 'ProductCategories',
  CONTENT_PAGES_INDEX: 'ContentPages',
  HELP_CENTER_INDEX: 'HelpCenterArticles',
  PRODUCT_QUERY_SUGGESTIONS_NL_NL: 'ProductPages_query_suggestions_nl-NL',
  PRODUCT_QUERY_SUGGESTIONS_NL_BE: 'ProductPages_query_suggestions_nl-BE',
  PRODUCT_QUERY_SUGGESTIONS_FR_BE: 'ProductPages_query_suggestions_fr-BE',
  PRODUCT_QUERY_SUGGESTIONS_FR_FR: 'ProductPages_query_suggestions_fr-FR',
};

export const SESSION_STORAGE_KEYS = {
  LIST_NAME: 'product_list_name',
  POSITION: 'product_list_position',
  HIDE_LOGIN_PROMPT: 'hide_login_prompt',
};

/**
 * Used for Product Recommendations component to validate if a component is in a view port or not.
 * 0.5=50%. If 50% of the component is withing the view port, it will trigger that component is within the view port
 */
export const VIEW_PORT_CHECK_PERCENTAGE = 1;
