import { createContext } from 'react';

export type TraitStatus = 'idle' | 'loading' | 'resolved' | 'error';

export interface ISegmentContext {
  traits: Record<string, any> | undefined;
  traitStatus: TraitStatus;
  isSegmentAnalyticsReady: boolean;
  consentData?: {
    necessary: boolean;
    preferences: boolean;
    statistics: boolean;
    marketing: boolean;
  }
  isConsentReady: boolean
}

const defaultValue: ISegmentContext = {
  traits: undefined,
  isSegmentAnalyticsReady: false,
  isConsentReady: false,
  traitStatus: 'idle',
};

const SegmentContext = createContext(defaultValue);

export default SegmentContext;
