import React from 'react';
import { Link } from 'gatsby';
import {
  Brand, type BrandName, type PrintdealBrandName, type ImpresstoBrandName,
} from '@printdeal/ui-library';
import useSystemPages from '../../../hooks/useSystemPages';

import { getConfiguration } from '../../../helpers/Configuration';

const configuration = getConfiguration();

const mapBrandName = (brandName: string): BrandName | PrintdealBrandName | ImpresstoBrandName | undefined => {
  switch (brandName) {
    case 'brandDrukwerkdealNL':
      return 'drukwerkdeal-nl';
    case 'brandPrintdealBE':
      return 'printdeal-be';
    case 'brandImpresstoFR':
      return 'impressto-fr';
    default:
      return undefined;
  }
};

type LogoProps = {
  className?: string;
};

const Logo = (props: LogoProps) => {
  const [homePage] = useSystemPages('home');
  const brand = mapBrandName(configuration.channel.brandName);

  if (!brand) {
    return null;
  }

  return (
    <Link to={homePage.path} data-cy="logo-link" {...props}>
      <Brand brand={brand} className="pd-h-6 sm:pd-h-8" />
      <span className="pd-sr-only">{configuration.channel.name}</span>
    </Link>
  );
};

export default Logo;
