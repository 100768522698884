exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-account-tsx": () => import("./../../../src/templates/account.tsx" /* webpackChunkName: "component---src-templates-account-tsx" */),
  "component---src-templates-alternative-upload-tsx": () => import("./../../../src/templates/alternative-upload.tsx" /* webpackChunkName: "component---src-templates-alternative-upload-tsx" */),
  "component---src-templates-authorize-printdeal-api-tsx": () => import("./../../../src/templates/authorize-printdeal-api.tsx" /* webpackChunkName: "component---src-templates-authorize-printdeal-api-tsx" */),
  "component---src-templates-blog-author-tsx": () => import("./../../../src/templates/blog/author.tsx" /* webpackChunkName: "component---src-templates-blog-author-tsx" */),
  "component---src-templates-blog-authors-tsx": () => import("./../../../src/templates/blog/authors.tsx" /* webpackChunkName: "component---src-templates-blog-authors-tsx" */),
  "component---src-templates-blog-categories-tsx": () => import("./../../../src/templates/blog/categories.tsx" /* webpackChunkName: "component---src-templates-blog-categories-tsx" */),
  "component---src-templates-blog-category-tsx": () => import("./../../../src/templates/blog/category.tsx" /* webpackChunkName: "component---src-templates-blog-category-tsx" */),
  "component---src-templates-blog-index-tsx": () => import("./../../../src/templates/blog/index.tsx" /* webpackChunkName: "component---src-templates-blog-index-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog/post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-blog-tag-tsx": () => import("./../../../src/templates/blog/tag.tsx" /* webpackChunkName: "component---src-templates-blog-tag-tsx" */),
  "component---src-templates-blog-tags-tsx": () => import("./../../../src/templates/blog/tags.tsx" /* webpackChunkName: "component---src-templates-blog-tags-tsx" */),
  "component---src-templates-cart-tsx": () => import("./../../../src/templates/cart.tsx" /* webpackChunkName: "component---src-templates-cart-tsx" */),
  "component---src-templates-cookie-declaration-tsx": () => import("./../../../src/templates/cookie-declaration.tsx" /* webpackChunkName: "component---src-templates-cookie-declaration-tsx" */),
  "component---src-templates-delivery-tsx": () => import("./../../../src/templates/delivery.tsx" /* webpackChunkName: "component---src-templates-delivery-tsx" */),
  "component---src-templates-design-experiences-packaging-diy-tsx": () => import("./../../../src/templates/designExperiences/packaging-diy.tsx" /* webpackChunkName: "component---src-templates-design-experiences-packaging-diy-tsx" */),
  "component---src-templates-help-center-article-tsx": () => import("./../../../src/templates/helpCenter/article.tsx" /* webpackChunkName: "component---src-templates-help-center-article-tsx" */),
  "component---src-templates-help-center-category-tsx": () => import("./../../../src/templates/helpCenter/category.tsx" /* webpackChunkName: "component---src-templates-help-center-category-tsx" */),
  "component---src-templates-help-center-index-tsx": () => import("./../../../src/templates/helpCenter/index.tsx" /* webpackChunkName: "component---src-templates-help-center-index-tsx" */),
  "component---src-templates-homepage-home-tsx": () => import("./../../../src/templates/homepage/home.tsx" /* webpackChunkName: "component---src-templates-homepage-home-tsx" */),
  "component---src-templates-login-tsx": () => import("./../../../src/templates/login.tsx" /* webpackChunkName: "component---src-templates-login-tsx" */),
  "component---src-templates-lost-password-tsx": () => import("./../../../src/templates/lost-password.tsx" /* webpackChunkName: "component---src-templates-lost-password-tsx" */),
  "component---src-templates-mandate-result-tsx": () => import("./../../../src/templates/mandate-result.tsx" /* webpackChunkName: "component---src-templates-mandate-result-tsx" */),
  "component---src-templates-page-container-tsx": () => import("./../../../src/templates/page-container.tsx" /* webpackChunkName: "component---src-templates-page-container-tsx" */),
  "component---src-templates-payment-method-tsx": () => import("./../../../src/templates/payment-method.tsx" /* webpackChunkName: "component---src-templates-payment-method-tsx" */),
  "component---src-templates-product-category-page-tsx": () => import("./../../../src/templates/product-category-page.tsx" /* webpackChunkName: "component---src-templates-product-category-page-tsx" */),
  "component---src-templates-product-page-tsx": () => import("./../../../src/templates/product-page.tsx" /* webpackChunkName: "component---src-templates-product-page-tsx" */),
  "component---src-templates-re-order-tsx": () => import("./../../../src/templates/re-order.tsx" /* webpackChunkName: "component---src-templates-re-order-tsx" */),
  "component---src-templates-register-thank-you-tsx": () => import("./../../../src/templates/register-thank-you.tsx" /* webpackChunkName: "component---src-templates-register-thank-you-tsx" */),
  "component---src-templates-register-tsx": () => import("./../../../src/templates/register.tsx" /* webpackChunkName: "component---src-templates-register-tsx" */),
  "component---src-templates-reset-password-tsx": () => import("./../../../src/templates/reset-password.tsx" /* webpackChunkName: "component---src-templates-reset-password-tsx" */),
  "component---src-templates-review-page-tsx": () => import("./../../../src/templates/review-page.tsx" /* webpackChunkName: "component---src-templates-review-page-tsx" */),
  "component---src-templates-search-results-tsx": () => import("./../../../src/templates/search-results.tsx" /* webpackChunkName: "component---src-templates-search-results-tsx" */),
  "component---src-templates-segmented-customer-tsx": () => import("./../../../src/templates/segmented-customer.tsx" /* webpackChunkName: "component---src-templates-segmented-customer-tsx" */),
  "component---src-templates-signup-page-segmented-customer-tsx": () => import("./../../../src/templates/signup-page-segmented-customer.tsx" /* webpackChunkName: "component---src-templates-signup-page-segmented-customer-tsx" */),
  "component---src-templates-submit-design-tsx": () => import("./../../../src/templates/submit-design.tsx" /* webpackChunkName: "component---src-templates-submit-design-tsx" */),
  "component---src-templates-thanks-for-order-tsx": () => import("./../../../src/templates/thanks-for-order.tsx" /* webpackChunkName: "component---src-templates-thanks-for-order-tsx" */)
}

