import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { QueryClientProvider } from '@tanstack/react-query';
import { CartProvider } from '@printdeal/checkout-frontend';
import { actions as authActions } from '../store/slices/authSlice';
import store from '../store';
import SegmentScript from './segmentation/SegmentScript';
import SegmentProvider from './segmentation/SegmentProvider';
import AnalyticsTrackerProvider from './AnalyticsTracker/AnalyticsTrackerProvider';

import '../provide-configuration-values';
import '../provide-implementations';
import AuthCompatibilityComponent from './authentication/AuthCompatibilityComponent';

import queryClient from '../queryClient';

interface Props {
  element: React.ReactNode;
}

const RootElementWrapper = ({ element }: Props) => {
  // Immediately authenticate on first load
  store.dispatch(authActions.authenticate());

  return (
    <React.StrictMode>
      <ReduxProvider store={store}>
        <QueryClientProvider client={queryClient}>
          <SegmentProvider>
            <AnalyticsTrackerProvider>
              <CartProvider>
                {element}
              </CartProvider>
            </AnalyticsTrackerProvider>
            <SegmentScript />
          </SegmentProvider>
          <AuthCompatibilityComponent />
        </QueryClientProvider>
      </ReduxProvider>
    </React.StrictMode>
  );
};

export default RootElementWrapper;
