import {
  Address, PriceAttribute, Prices, PriceGroup, Sort, Price,
} from '@printdeal/product-selector';
import { getKeyValueObjectOfMatchedOutcomes } from '../../helpers/MerchantRulesHelper';
import Api from '../Api';
import { AttributeFormat, MatchedOutcome, Rules } from '../../types/product/ProductRules';
import { ISelection } from '../../types/selector/Selector';
import { McpProduct } from '../../types/product/product';

export interface GetProductPricesParams {
  productKey: string,
  variableAttributes: PriceAttribute[],
  address: Address,
  quantity: number[],
  mcpSku: string,
  customerGroupId?: string,
  sort: Sort[],
  group?: PriceGroup[],
  customHeaders?: Record<string, string>,
}

class ProductService extends Api {
  /**
   * Evaluate merchant rules with the givin facts
   * @param {string} productSku - Sku of the product to match the rules against
   * @param {array<object>} facts - Example,
   * if areAttributeLabels = false: [{ "key": "Printing_Colors___LOV", "value": "4/4 Full Color"}]
   * if areAttributeLabels = true: UserSelection
   * @param {bool} areAttributeLabels - To switch between facts format
   * @returns {Promise<*>}
   */
  async evaluateMerchantRules(
    productSku: string,
    facts: AttributeFormat[],
    areAttributeLabels: boolean = true,
  ): Promise<MatchedOutcome[] | null> {
    try {
      const params = new URLSearchParams();
      params.append('facts', JSON.stringify(facts));
      params.append('areAttributeLabels', String(areAttributeLabels));
      const { data: { matchedOutcomes } } = await this.httpClient.get(
        `/product/evaluateMerchantRules/${productSku}?${params}`,
      );
      // eslint-disable-next-line @typescript-eslint/return-await
      return Promise.resolve(matchedOutcomes);
    } catch (e) {
      return Promise.resolve(null);
    }
  }

  /**
   * Get delivery tool settings
   * @param {string} productSku - Sku of the product to match the rules against
   * @param {array<object>} facts - Example,
   * if areAttributeLabels = false: [{ "key": "Printing_Colors___LOV", "value": "4/4 Full Color"}]
   * @param {boolean} areAttributeLabels
   */
  async getMerchantRules(
    productSku: string,
    facts: AttributeFormat[],
    areAttributeLabels: boolean = true,
  ): Promise<Rules> {
    const defaults = {
      checkmate: 'off',
      editor: 'off',
      altuploadbeforeorder: 'off',
    };

    try {
      const ruleOutcomes = await this.evaluateMerchantRules(productSku, facts, areAttributeLabels);

      return {
        deliveryTool: {
          ...defaults,
          ...getKeyValueObjectOfMatchedOutcomes(
            ruleOutcomes,
            [
              'editor',
              'editorMode',
              'checkmate',
              'checkmateMode',
              'templateURL',
              'multipleDesigns',
              'ncolorcount',
              'assetTenant',
              'altUploadBeforeOrder',
              'EditorOverrides',
              'serviceKeys',
            ],
          ),
        },
        ...getKeyValueObjectOfMatchedOutcomes(ruleOutcomes, ['cutOffTime']),
      };
    } catch (e) {
      return { deliveryTool: defaults };
    }
  }

  getPrices = async ({
    productKey,
    variableAttributes,
    address,
    quantity,
    mcpSku,
    customerGroupId,
    sort,
    group = [],
    customHeaders,
  }: GetProductPricesParams): Promise<Prices> => {
    const params = new URLSearchParams();
    params.append('address', JSON.stringify(address));
    params.append('productKey', productKey);
    params.append('variableAttributes', JSON.stringify(variableAttributes));
    params.append('quantityList', JSON.stringify(quantity));
    params.append('mcpSku', mcpSku);
    params.append('sort', JSON.stringify(sort));
    params.append('group', JSON.stringify(group));
    params.append('shouldCombineServicePrice', 'true');

    if (customerGroupId) {
      params.append('customerGroupId', customerGroupId);
    }

    const path = sort.find(({ pivotAttribute }) => ['Printing Process', 'Printing'].includes(pivotAttribute)) ? 'getPrices' : 'prices';
    const { data } = await this.httpClient.get<Prices>(`/${path}?${params}`, {
      headers: {
        'x-filename': 'ProductService.ts',
        'x-href': window?.location?.href,
        ...customHeaders,
      },
    });

    return data || [];
  };

  /**
   * This can be used as a fetcher
   */
  get = async <T>(url: string) => {
    const { data } = await this.httpClient.get<T>(url);
    return Promise.resolve(data);
  };

  validateSelection = async ({
    selection,
    comboSelection,
    productDetailPageId,
    customerGroupId,
  }: {
    selection: string,
    comboSelection: string,
    productDetailPageId: string,
    customerGroupId?: string
  }): Promise<{ valid: boolean, price: Price }> => {
    const params = new URLSearchParams();
    params.append('selection', selection);

    if (comboSelection) {
      params.append('comboSelection', comboSelection);
    }

    if (customerGroupId) {
      params.append('customerGroupId', customerGroupId);
    }

    const { data } = await this.httpClient.get(`/product/validate/${productDetailPageId}`, {
      params,
    });
    return Promise.resolve(data);
  };

  convertProduct = async (
    productKey: string, variableAttributes: ISelection, customHeaders?: Record<string, string>,
  ) => {
    const params = new URLSearchParams();
    params.append('variableAttributes', JSON.stringify(variableAttributes));
    const { data: mcpProduct } = await this.httpClient.get<McpProduct>(`/product/convert/${productKey}`, {
      params,
      headers: customHeaders || {},
    });
    return Promise.resolve(mcpProduct);
  };
}

export default ProductService;
