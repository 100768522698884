import Api from '../Api';
import { SentryHelper } from '../../helpers/SentryHelper';

interface File {
  [key: string]: {
    originalSourceUrl: string;
    previewUrl: string;
    fileName: string;
  }
}

class PrintQService extends Api {
  async getUploadedFiles(docRef: string): Promise<File | null> {
    try {
      const params = new URLSearchParams();
      params.append('docRef', docRef);
      const { data } = await this.httpClient.get<File>(`getUploadedFiles?${params}`);

      return data;
    } catch (error) {
      SentryHelper.exception({
        e: error,
        fingerPrint: ['printq-service-get-uploaded-files'],
      });
    }
    return null;
  }
}

export default PrintQService;
