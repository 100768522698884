import {
  takeLatest, put, call, select, fork, all, takeLeading,
} from 'redux-saga/effects';
import { type JwtPayload } from 'jwt-decode';
import { actions as authActions } from '../slices/authSlice';
// @ts-expect-error
import { selectors as customerSelectors } from '../slices/customerSlice';
// @ts-expect-error
import { getCustomer, getAccount } from './customerSaga';

// Helpers
import {
  LS_KEYS, getFromLocalStorage, setInLocalStorage, removeFromLocalStorage,
} from '../../helpers/window';
import {
  getAnonymousToken,
  isDecodedTokenExpired,
  getAnonymousCOAMIdentity,
  AnonymousCoamIdentity,
  decodeJTW,
  decodePrintdealToken,
  type PrintdealAuthTokenPayload,
} from '../../helpers/auth';
import { getHumanErrorFromResponse } from '../../helpers/error';
// @ts-expect-error
import { AuthDataLayerHelper } from '../../helpers/dataLayerHelpers/AuthDataLayerHelper';
import { SentryHelper } from '../../helpers/SentryHelper';
import Cart from '../../types/cart/Cart';
import { IAuthToken } from '../../types/AuthToken';

interface ILogin {
  payload: {
    accountPathUrl: string;
    cartPathUrl: string;
    givenRedirectUrl: string;
    token: string;
    cart: Cart;
    account?: IAuthToken;
  };
}

/**
 * @param {object} param
 * @param {object} param.payload
 */
export function* login({ payload }: ILogin) {
  yield put(authActions.loginRequest());

  try {
    const {
      ...payloadForLoginSuccess
    } = payload;

    // Decode the token from the payload
    const decodedToken: PrintdealAuthTokenPayload = yield call(decodePrintdealToken, payload.token);
    payloadForLoginSuccess.account = decodedToken.account;

    yield call(getCustomer, { payload: { decodedToken } });

    // Save customer data in storage
    const customerNumber: string = yield select(customerSelectors.getCustomerNumber);
    const customerType: string = yield select(customerSelectors.getCustomerType);

    // Trigger GTA event
    yield call(
      [AuthDataLayerHelper, 'dispatchDataLayerEventOnLogin'],
      decodedToken,
      { customerNumber, customerType },
      true,
    );

    yield put(authActions.loginSuccess(payloadForLoginSuccess));
  } catch (error) {
    yield put(authActions.loginFailure({
      error: getHumanErrorFromResponse(error),
      fullError: error,
    }));
  }
}

export function* logout() {
  yield put(authActions.logoutRequest());

  try {
    // Get a new anonymous token
    const anonymousToken: string = yield call(getAnonymousToken);
    // Trigger GTA event
    yield call([AuthDataLayerHelper, 'dispatchDataLayerEventOnLogout']);
    // Remove selected customer data to localStorage
    yield fork(removeFromLocalStorage, LS_KEYS.CUSTOMER_NUMBER);
    yield fork(removeFromLocalStorage, LS_KEYS.CUSTOMER_TYPE);

    yield put(authActions.logoutSuccess({
      token: anonymousToken,
    }));
  } catch (error) {
    yield put(authActions.logoutFailure({
      error: getHumanErrorFromResponse(error),
    }));
  }
}

type AuthenticateAction = undefined | {
  payload: string;
};

export function* authenticate(action: AuthenticateAction) {
  /**
   * To notify this authenticate action of token changes in an external authentication layer,
   * this method is called with the token from that external authentication layer so that
   * this authenticate action can synchronize with it (e.g. deal with customer information, etc).
   *
   * The regular authenticate call (done in launchpad itself), is without token in the payload,
   * so then the authenticate action can just grab the token from localStorage as usual.
   */
  const payload: string | undefined = action ? action.payload : undefined;

  yield put(authActions.authRequest());

  const token: string = yield payload ?? call(getFromLocalStorage, LS_KEYS.AUTH_TOKEN);

  try {
    const decodedToken: PrintdealAuthTokenPayload = token ? yield call(decodePrintdealToken, token) : undefined;

    if (!decodedToken || (yield call(isDecodedTokenExpired, decodedToken))) {
      const anonymousToken: string = yield call(getAnonymousToken);
      // Trigger GTA event
      yield call([AuthDataLayerHelper, 'dispatchDataLayerEventOnLogout']);
      // Remove selected customer data to localStorage
      yield fork(removeFromLocalStorage, LS_KEYS.CUSTOMER_NUMBER);
      yield fork(removeFromLocalStorage, LS_KEYS.CUSTOMER_TYPE);

      const result: void = yield put(authActions.authSuccess({
        isLoggedIn: false,
        token: anonymousToken,
      }));
      return result;
    }

    // If the token is an anonymous token
    if (decodedToken && decodedToken.anonymous) {
      const authSuccessResult: void = yield put(authActions.authSuccess({
        isLoggedIn: false,
        token,
      }));
      return authSuccessResult;
    }

    const isCustomerLoaded: boolean = yield select(customerSelectors.isCustomerLoaded);
    const isAccountLoaded: boolean = yield select(customerSelectors.isAccountLoaded);
    if (!decodedToken.anonymous && !isCustomerLoaded) {
      // Trigger GTA event
      yield call(
        [AuthDataLayerHelper, 'dispatchDataLayerEventOnAuthenticate'],
        decodedToken,
      );
    }

    yield all([
      !isCustomerLoaded && call(getCustomer, { payload: { decodedToken } }),
      !isAccountLoaded && call(getAccount, { payload: { decodedToken } }),
    ]);

    try {
      performance.mark('authenticated-customer');
    } catch (error) {
      // Fail silently for non-supporting browsers
    }

    const result: void = yield put(authActions.authSuccess({
      isLoggedIn: true,
      token,
    }));
    return result;
  } catch (error) {
    const authFailureResult: void = yield put(authActions.authFailure({
      error: getHumanErrorFromResponse(error),
    }));
    return authFailureResult;
  }
}

/**
 * This saga checks if there's an anonymous identity in localStorage.
 * - If so and the identity is still valid, it will store the identity in the redux-store
 * - If not or the identity is expired,
 *      it will get a new/updated anonymous identity and store it in redux-store and localStorage
 * @knipignore
 */
export function* checkAnonymousCOAMIdentityAvailability() {
  // Update the redux-store that we have started the validation process
  yield put(authActions.validateAnonymousCOAMIdentity());

  try {
    // Check in localStorage for an existing identity
    const identityFromLocalStorage: string | undefined = yield call(getFromLocalStorage, LS_KEYS.ANON_COAM_IDENTITY);
    // If there is an identity in localStorage, parse it.
    const identity = identityFromLocalStorage
      ? JSON.parse(identityFromLocalStorage) as AnonymousCoamIdentity
      : undefined;
    const decodedToken = {};

    // If there is an identity in localStorage, decode the token
    if (identity) {
      const decodeResult: JwtPayload = yield call(decodeJTW, identity.anonymousToken);
      Object.assign(decodedToken, decodeResult);
    }

    // If there is no identity in localStorage, or if the token is expired
    if (!identity || (yield call(isDecodedTokenExpired, decodedToken))) {
      // Fetch an (updated) identity
      const updatedIdentity: AnonymousCoamIdentity = yield call(getAnonymousCOAMIdentity, identity);
      // Update the redux store with the new identity
      yield put(authActions.validateAnonymousCOAMIdentitySuccess(updatedIdentity));
      // Save the new identity in localStorage
      const storageResult: void = yield call(
        setInLocalStorage,
        LS_KEYS.ANON_COAM_IDENTITY,
        JSON.stringify(updatedIdentity),
      );
      return storageResult;
    }

    // The identity is still valid, so update the redux store with the identity
    const validationResult: void = yield put(authActions.validateAnonymousCOAMIdentitySuccess(identity));
    return validationResult;
  } catch (exception) {
    SentryHelper.exception({
      e: exception,
      tags: {
        fileLocation: 'authSaga',
        featureFunction: 'checkAnonymousCOAMIdentityAvailability',
      },
      fingerPrint: ['auth-saga-check-anon-coam-identity'],
    });

    const validationResult: void = yield put(authActions.validateAnonymousCOAMIdentityFailure({
      error: getHumanErrorFromResponse(exception),
    }));
    return validationResult;
  }
}

export default [
  // @ts-expect-error
  takeLatest(authActions.login, login),
  takeLatest(authActions.logout, logout),
  // @ts-expect-error
  takeLeading(authActions.authenticate, authenticate),
  takeLeading(authActions.authenticate, checkAnonymousCOAMIdentityAvailability),
];
