import { addDataLayerEntry } from '@printdeal/data-layer-manager';
// @ts-expect-error - still using index.js
import { updateMetric } from '@cimpress-technology/recommendation-library';
import { SESSION_STORAGE_KEYS } from '../../../gatsby/constants';
import { RecommendationsConfig } from '../../types/Configuration';
import { IProductPage } from '../../types/product/productDetailPage';
import type { AnalyticsTrackerContextInterface } from '../AnalyticsTracker/AnalyticsTrackerContext';
import {
  publishEventToSegemnt,
  preparePayloadForListViewed,
  preparePayloadForProductCliked,
  EVENT_TYPES_FOR_CT_RECOMMENDATIONS,
} from '../AnalyticsTracker/AnalyticsTrackerHelper';
import type { EventDataPayload } from '../AnalyticsTracker/AnalyticsTrackerHelper';
import type { ConsentData } from '../../helpers/CookiebotHelper';

interface DatalayerProducts {
  name: string | undefined;
  id: string;
  dimension39: string | undefined;
  category: string;
  list: string | undefined;
  position: string;
}

export interface Scenario {
  recommendationId: string;
  recommendations: {
    publishedContent: IProductPage;
  }[];
  title: string;
  analyticsId: string;
  placementId: string;
}

interface InViewRecommendation {
  products: IProductPage[];
  analyticsId: string;
  recommendationId: string;
  placementId: string;
}

type GetNewConfig = Pick<
RecommendationsConfig,
'locations' | 'customerId' | 'tenant' | 'locale'
| 'gaClientId' | 'item_id' | 'productsOnPage'
| 'productsInCart' | 'placementStates' | 'placementId'>;

const Events = {
  eec: 'gaEventEec',
};

const Categories = {
  productRecommender: 'product_recommender',
  eCommerce: 'ecommerce',
};

const Actions = {
  click: 'click',
  view: 'view',
  impressions: 'impressions',
};

const FOR_YOU_PAGE_RECOMMENDATIONS = 'recommended_for_you';

const recommendationViewed = (
  analyticsTrackerData: AnalyticsTrackerContextInterface,
  recommendationId: string,
  analyticsId: string,
  placementId: string,
  productsForDataLayer: DatalayerProducts[],
  anonymousCOAMToken: string | undefined,
  config: RecommendationsConfig | null,
  consentData: ConsentData | null,
) => {
  const [, dispatchToAnalyticsTracker] = analyticsTrackerData;
  /**
   * Calling updateMetric() from ct recommendations API
   */
  const payload = {
    token: anonymousCOAMToken,
    tenant: config?.tenant,
    recommendationId,
    action: 'viewed',
  };
  updateMetric(payload);
  addDataLayerEntry(Events.eec, {
    gaEvent: {
      eventCategory: Categories.eCommerce,
      eventAction: Actions.impressions,
      eventLabel: undefined,
      eventValue: undefined,
      eventNonInteraction: false,
    },
    [Categories.eCommerce]: {
      impressions: productsForDataLayer,
    },
  });

  const PayloadForRecommendationListViewed = {
    trackingIds: {
      recommendationId,
      analyticsId,
      placementId,
      userId: config?.customerId,
    },
  };

  /**
   * Dispatching TrackingIDs to AnalyticsTracker Context
   */
  dispatchToAnalyticsTracker('CT_ADD_NEW_TRACKING_DATA', PayloadForRecommendationListViewed);

  /**
   * Prepairing payload for "Recommendation List Viewed" Event
   */
  const listViewedPayload: EventDataPayload = preparePayloadForListViewed(
    recommendationId,
    placementId,
    productsForDataLayer,
    config,
    consentData,
  );

  /**
  * Publising payload for "Recommendation List Viewed" Event
  * Event publsihed to Segment for Recommendation Engine not for traits
  */
  publishEventToSegemnt(EVENT_TYPES_FOR_CT_RECOMMENDATIONS.LIST_VIEWED, listViewedPayload);
};

const productClicked = (
  analyticsTrackerData: AnalyticsTrackerContextInterface,
  recommendationId: string,
  product: IProductPage,
  analyticsId: string,
  placementId: string,
  productPosition: number,
  anonymousCOAMToken: string | undefined,
  config: RecommendationsConfig | null,
  consentData: ConsentData | null,
) => {
  const [, dispatchToAnalyticsTracker] = analyticsTrackerData;
  // Calling the method to inform that Recommendation was clicked
  const payload = {
    token: anonymousCOAMToken,
    tenant: config?.tenant,
    recommendationId,
    action: 'clicked',
  };
  updateMetric(payload);

  // Update SessionStorage
  sessionStorage.setItem(SESSION_STORAGE_KEYS.LIST_NAME, analyticsId);
  sessionStorage.setItem(SESSION_STORAGE_KEYS.POSITION, `${productPosition}`);

  // Update DataLayer
  addDataLayerEntry(Events.eec, {
    gaEvent: {
      eventCategory: Categories.eCommerce,
      eventAction: Actions.click,
      eventLabel: product.productName || undefined,
      eventValue: undefined,
      eventNonInteraction: false,
    },
    [Categories.eCommerce]: {
      click: {
        actionField: {
          list: analyticsId,
        },
        products: [{
          name: product.productName || undefined,
          id: product.parentProduct && product.parentProduct.key ? product.parentProduct.key : '',
          dimension39: product.productName || undefined,
          category: product.productCategory && product.productCategory.categoryName ? product.productCategory.categoryName : '',
          position: `${productPosition}`,
        }],
      },
    },
  });

  const payloadForProductClickedTrail = preparePayloadForProductCliked(
    recommendationId,
    product,
    analyticsId,
    productPosition,
    placementId,
    config,
    consentData,
  );

  /**
  * Publising payload for "Recommendation Clicked" Event
  * Event publsihed to Segment for Recommendation Engine not for traits
  */
  publishEventToSegemnt(EVENT_TYPES_FOR_CT_RECOMMENDATIONS.PRODUCT_CLICKED, payloadForProductClickedTrail);

  /**
  * Dispatching Product Clicked Trail from the placement to AnalyticsTracker Context
  */
  dispatchToAnalyticsTracker('CT_ADD_NEW_TRACKING_DATA_FOR_EVENTS_TRAIL', {
    productId: product.contentful_id,
    placementId,
    trailName: 'productsClicked',
  });
};

export const observerCallback = (
  analyticsTrackerData: AnalyticsTrackerContextInterface,
  inView: boolean,
  entry: IntersectionObserverEntry,
  anonymousCOAMToken: string | undefined,
  config: RecommendationsConfig | null,
  onView: Function,
  consentData: ConsentData | null,
) => {
  if (inView) {
    const {
      products, analyticsId, placementId, recommendationId,
    } = JSON.parse(entry?.target?.attributes?.getNamedItem('data-recommendations')?.value || '') as InViewRecommendation;
    const productsForDataLayer: DatalayerProducts[] = products.map((product: IProductPage, index: number) => ({
      name: product.productName || undefined,
      id: product.parentProduct && product.parentProduct.key ? product.parentProduct.key : '',
      product_id: product.contentful_id,
      prUUID: product.contentful_id,
      dimension39: product.productName || undefined,
      category: product.productCategory && product.productCategory.categoryName ? product.productCategory.categoryName : '',
      list: analyticsId,
      list_id: analyticsId,
      position: `${index + 1}`,
    }));
    recommendationViewed(
      analyticsTrackerData,
      recommendationId,
      analyticsId,
      placementId,
      productsForDataLayer,
      anonymousCOAMToken,
      config,
      consentData,
    );
    onView(products, analyticsId);
  }
};

export const productClickHandlerForDataLayerTrigger = (
  analyticsTrackerData: AnalyticsTrackerContextInterface,
  recommendationId: string,
  product: IProductPage,
  analyticsId: string,
  placementId: string,
  productPosition: number,
  anonymousCOAMToken: string | undefined,
  config: RecommendationsConfig | null,
  clickHandler: Function,
  consentData: ConsentData | null,
) => {
  productClicked(
    analyticsTrackerData,
    recommendationId,
    product,
    analyticsId,
    placementId,
    productPosition,
    anonymousCOAMToken,
    config,
    consentData,
  );
  clickHandler(product.productName, productPosition);
};

/**
 * ajs_user_id is set by segment using JSON.stringify, so has double quotes in it, in the LS value
 * @param lString string or undefined
 * Retrieving the value using global regxp replace
 * @returns string
 */
export const parseStringifiedValue = (lString: string | undefined) => {
  /**
   * return empty string if LS value for the key ajs_user_id, is not a string
   * should be string always as per segment docs
   */
  if (lString && typeof lString === 'string') {
    return lString.replace(/['"]+/g, '');
  }
  return '';
};

/**
 *
 * @param data Event data for GA4
 */
const dispatchDataLayerEventOnProductsClickGA4 = (data: {
  eventName: string;
  position: number;
  productName: string;
}) => {
  const { eventName, position, productName } = data;
  addDataLayerEntry(
    eventName,
    {
      position,
      productName,
    },
  );
};

/**
 *
 * @param productName Product name of the clicked product from a placement
 * @param position order/postion of the product in placement
 */
export const handleForYouPageProductOnClick = (productName: string, position: number) => {
  const eventData = {
    eventName: FOR_YOU_PAGE_RECOMMENDATIONS,
    position,
    productName,
  };
  dispatchDataLayerEventOnProductsClickGA4(eventData);
};

/**
 *
 * @param componentName Name of the placement
 * @param accountId Account id/ Customer Id of the looged in user
 * @param locale Local Information from intl
 * @returns New Configuration for the getRecommendations api payload
 */

export const getNewConfig = (
  componentName: string,
  accountId: string,
  locale: string,
): GetNewConfig => ({
  locations: componentName,
  customerId: accountId as string,
  tenant: process.env.GATSBY_RECOMMENDATIONS_TENANT,
  locale,
});

/**
 *
 * @param scenarios Placement product list
 * @returns Transformed product list for the component
 */
export const getScenariosFromPlacement = (scenarios: Scenario[]) => {
  const scenarioProductsToDisplay = scenarios.reduce((acc, scenario: Scenario) => {
    const filteredProducts = scenario.recommendations
      .filter((product) => product.publishedContent)
      .map((product) => product.publishedContent);
    return {
      ...acc,
      [scenario.recommendationId]: filteredProducts,
    };
  }, {});

  return scenarioProductsToDisplay;
};
