import ComplaintsService from './complaints/ComplaintsService';
import AuthService from './customer/AuthService';
import CustomerService from './customer/CustomerService';
import DesignService from './designs/DesignService';
import FileDeliveryService from './file-delivery/FileDeliveryService';
import { HelpCenterService } from './help-center/HelpCenterService';
import { HttpClientFactory } from './HttpClientFactory';
import InvoiceService from './invoice/InvoiceService';
import ItpService from './itp/ItpService';
import MandatesService from './mandates/MandatesService';
import OrderService from './order/OrderService';
import PaymentV2Service from './payment/PaymentV2Service';
import ProductService from './product/ProductService';
import ValidationService from './product/ValidationService';
import QuotesService from './quotes/QuotesService';
import FinpackService from './finpack/FinpackService';
import ArtworkService from './artwork/ArtworkService';
import PrintQService from './printQ/PrintQService';
import Services from './service/Services';
import { PortalMessage } from './content/PortalMessage';
import { HomePageService, buildContenfulBaseURL } from './home/HomePageService';
import CustomerProposalService from './customer/CustomerProposalService';
import SurfaceDependencyService from './product/SurfaceDependencyService';

// eslint-disable-next-line import/no-unresolved
const portalConfiguration = require('../../static/configuration/configuration.json');

class ServiceFactory {
  static createAuthService() {
    return new AuthService(HttpClientFactory.createHttpClient(
      process.env.GATSBY_AUTH_ENDPOINT as string,
    ));
  }

  static createCustomerService() {
    return new CustomerService(HttpClientFactory.createHttpClient(
      process.env.GATSBY_CUSTOMER_URL as string,
    ));
  }

  static createCustomerProposalService() {
    return new CustomerProposalService(HttpClientFactory.createHttpClient(
      process.env.GATSBY_FILE_REVIEW_SERVICE_URL as string,
    ));
  }

  static createOrderService(): OrderService {
    return new OrderService(HttpClientFactory.createHttpClient(
      process.env.GATSBY_LAUNCHPAD_ORDER_URL as string,
    ));
  }

  static createPaymentV2Service(): PaymentV2Service {
    return new PaymentV2Service(HttpClientFactory.createHttpClient(
      process.env.GATSBY_LAUNCHPAD_PAYMENT_V2_URL as string,
    ));
  }

  static createProductService(): ProductService {
    return new ProductService(HttpClientFactory.createHttpClient(
      process.env.GATSBY_PRODUCT_ENDPOINT as string,
    ));
  }

  static createMandatesService(): MandatesService {
    return new MandatesService(HttpClientFactory.createHttpClient(
      process.env.GATSBY_MANDATES_ENDPOINT as string,
    ));
  }

  static createDesignService(): DesignService {
    return new DesignService(HttpClientFactory.createHttpClient(
      process.env.GATSBY_DESIGNS_ENDPOINT as string,
    ));
  }

  static createQuotesService(): QuotesService {
    return new QuotesService(HttpClientFactory.createHttpClient(
      process.env.GATSBY_QUOTES_ENDPOINT as string,
    ));
  }

  static createInvoiceService(): InvoiceService {
    return new InvoiceService(HttpClientFactory.createHttpClient(
      process.env.GATSBY_INVOICE_V2_URL as string,
    ));
  }

  static createComplaintsService(): ComplaintsService {
    return new ComplaintsService(HttpClientFactory.createHttpClient(
      process.env.GATSBY_LAUNCH_COMPLAINT_URL as string,
    ));
  }

  static createFileDeliveryService(): FileDeliveryService {
    return new FileDeliveryService(HttpClientFactory.createHttpClient(
      process.env.GATSBY_FILE_DELIVERY_URL as string,
    ));
  }

  static createHelpCenterService(): HelpCenterService {
    return new HelpCenterService(HttpClientFactory.createHttpClient(
      portalConfiguration.baseUrls.HELP_CENTER_API,
    ));
  }

  static createValidationService(): ValidationService {
    return new ValidationService(HttpClientFactory.createCOAMClient(
      process.env.GATSBY_PRODUCT_VALIDATION_URL as string,
    ));
  }

  static createSurfaceDependencyService(): SurfaceDependencyService {
    return new SurfaceDependencyService(HttpClientFactory.createCOAMClient(
      process.env.GATSBY_DESIGN_TEMPLATE_GENERATOR_BASE_URL as string,
    ));
  }

  static createItpService(): ItpService {
    return new ItpService();
  }

  static createFinpackService(): FinpackService {
    return new FinpackService(HttpClientFactory.createHttpClient(
      process.env.GATSBY_FINPACK_URL as string,
    ));
  }

  static createArtworkService(): ArtworkService {
    return new ArtworkService(HttpClientFactory.createHttpClient(
      portalConfiguration.baseUrls.ARTWORK_API,
    ));
  }

  static createServices(): Services {
    return new Services(HttpClientFactory.createHttpClient(
      process.env.GATSBY_SERVICES_ENDPOINT as string,
    ));
  }

  static createPortalMessageService(): PortalMessage {
    return new PortalMessage(HttpClientFactory.createHttpClient(
      process.env.GATSBY_SYSTEM_STATUS_URL as string,
      false,
    ));
  }

  static createHomePageService() {
    const baseurl = buildContenfulBaseURL(
      process.env.GATSBY_CONTENTFUL_GRAPHQL_API_BASE_URL as string,
      process.env.GATSBY_CONTENTFUL_SPACE_ID as string,
      process.env.GATSBY_CONTENTFUL_ENVIRONMENT as string,
    );
    return new HomePageService(HttpClientFactory.createContenfulClient(baseurl));
  }

  static createPrintQService(): PrintQService {
    return new PrintQService(HttpClientFactory.createHttpClient(
      process.env.GATSBY_PRINTQ_API_BASE_URL as string,
    ));
  }
}

export { ServiceFactory };
