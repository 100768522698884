import { Root, Panel, Heading } from '@printdeal/ui-library';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import NextStepsForUserAfterError from './NextStepsForUserAfterError';

const ErrorBoundaryBody = () => (
  <Root as="section" className="pd-mb-3">
    <Panel>
      <Heading as="h2" className="pd-mb-3">
        <FormattedMessage
          id="general.errorBoundary.v2.header"
          defaultMessage="We're sorry! Something went wrong"
        />
      </Heading>
      <p className="pd-mb-3 pd-text-sm">
        <FormattedMessage
          id="general.errorBoundary.v2.body"
          defaultMessage="Please try the following options:"
        />
      </p>
      <NextStepsForUserAfterError showDebugId showRetryOption />
    </Panel>
  </Root>
);

export default ErrorBoundaryBody;
