import {
  Bank, CancelMandateResponse, CreateMandateResponse, Mandate, Transaction,
} from '../../types/mandates/Mandate';
import Api from '../Api';

class MandatesService extends Api {
  async getMandates(): Promise<Mandate[]> {
    const { data } = await this.httpClient.get<Mandate[]>('/mandates');
    return data;
  }

  async getStatus(transactionId: string): Promise<Transaction> {
    const { data } = await this.httpClient.post<Transaction>('/status', { transactionId });
    return data;
  }

  async getMandate(mandateId: string): Promise<Mandate> {
    const { data } = await this.httpClient.get<Mandate>(`/mandate?id=${mandateId}`, { blockAnonymous: true });
    return data;
  }

  async getBanks(): Promise<Bank[]> {
    const { data } = await this.httpClient.get<Bank[]>('/banks');
    return data;
  }

  async createMandate(mandate: Mandate): Promise<CreateMandateResponse> {
    const { data } = await this.httpClient.post<CreateMandateResponse>('/create', mandate);
    return data;
  }

  async cancelMandate(emandateId: string, returnUrl: string): Promise<CancelMandateResponse> {
    const { data } = await this.httpClient.post<CancelMandateResponse>('/cancel', { emandateId, returnUrl });
    return data;
  }
}

export default MandatesService;
