const GeneralSettings = {};

/**
 * @deprecated
 * Instead, use the AddressPurpose type from '@printdeal/address-shared'.
 *
 * TODO (CHKOUT-1443): remove once old address book code from customerSaga/customerSlice is gone.
 */
GeneralSettings.AddressTypes = {
  BILLING: 'billing',
  SHIPPING: 'shipping',
};

GeneralSettings.AttributeTypes = {
  RANGE: 'range',
  VALUE: 'value',
};

GeneralSettings.ShippingAddressTypes = {
  COMMERCIAL: 'Commercial',
  RESIDENTIAL: 'Residential',
};

GeneralSettings.merchantRuleTypes = {
  STAYONPAGE: 'stayOnPage',
  SHIPPINGCOSTS: 'shippingCosts',
};

GeneralSettings.attributePimNames = {
  quantity: 'Quantity',
  quantityWithLOV: 'Quantity___LOV',
  deliveryType: 'Delivery Type',
  printingProcess: 'Printing Process',
};

GeneralSettings.pagination = {
  ordersOverview: {
    perPage: 20,
  },
  /**
   * @deprecated
   * TODO (CHKOUT-1443): remove once old address book code from customerSaga/customerSlice is gone.
   */
  addressBook: {
    perPage: 8,
  },
};

GeneralSettings.discountErrorMessage = {
  START: 'The discountCode',
  END: 'cannot be applied to the cart',
  FULL: 'The discountCode cannot be applied to the cart',
};

export {
  GeneralSettings,
};
