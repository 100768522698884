import Cookies from 'js-cookie';

const windowPresent = (typeof window !== 'undefined');

const LS_KEYS = {
  AUTH_TOKEN: 'authToken',
  CART_ID: 'cartId',
  CUSTOMER_NUMBER: 'customerNumber',
  CUSTOMER_TYPE: 'customerType',
  PENDING_PAYMENTS: 'pendingPayments',
  SEARCH_CLICK_EVENT: 'searchClickEvent',
  ANONYMOUS_ID: 'anonymousId',
  SEGMENT_TRAITS: 'segmentTraits',
  ANON_COAM_IDENTITY: 'anonCoamIdentity',
  DEBUG_ID: 'debugId',
  VAT_MODE: 'vatMode',
  AJS_USER_ID: 'ajs_user_id',
};

const syncToCookie = new Set([
  LS_KEYS.AUTH_TOKEN,
  LS_KEYS.DEBUG_ID,
  LS_KEYS.SEGMENT_TRAITS,
  LS_KEYS.VAT_MODE,
]);

function setCookie(key: string, value: string): void {
  if (syncToCookie.has(key)) {
    Cookies.set(key, value, {
      path: '/',
      sameSite: 'Strict',
      expires: 365, // days
    });
  }
}

function getCookie(key: string) {
  if (syncToCookie.has(key)) {
    return Cookies.get(key);
  }

  return undefined;
}

const getFromLocalStorage = (key: string): string | undefined => {
  if (!windowPresent) {
    return undefined;
  }

  const localStorageItem = window.localStorage.getItem(key);

  if (localStorageItem === null || localStorageItem === '') {
    // It is possible that a customer visited an SSR page first, meaning they could
    // have the cookie value, but it's not in LS (yet);
    // We're intentionally not syncing back to LS, because have information in multiple
    // places will only increase chances of it getting out of sync at some point.
    return getCookie(key);
  }

  // Temp. sync LS items to cookie so they can be used in Server Side Rendering
  // but only if local storage has the item and the item should be synced
  setCookie(key, localStorageItem);

  return localStorageItem;
};

const setInLocalStorage = (key: string, value: string): void => {
  if (!windowPresent) {
    return;
  }
  window.localStorage.setItem(key, value);
  setCookie(key, value);
};

const removeFromLocalStorage = (key: string): void => {
  if (!windowPresent) {
    return;
  }
  window.localStorage.removeItem(key);
  Cookies.remove(key);
};

const getScrollBarWidth = (): number => {
  if (!windowPresent) {
    return 0;
  }
  // window.innerwidth has the scrollbar, documentElement (HTML tag) has no scrollbar
  return window.innerWidth - document.documentElement.clientWidth;
};

export {
  windowPresent,
  getScrollBarWidth,
  getFromLocalStorage,
  setInLocalStorage,
  removeFromLocalStorage,
  LS_KEYS,
};
