import Api from '../Api';

export interface GetInvoiceParams {
  dates: {
    fromDate: string;
    toDate: string;
  };
  chunkSize?: number;
  continuationToken?: string;
  paymentStatusFilter?: string;
  isLegacy?: boolean;
  orderNumber?: string;
}

export interface CreateChangeAscriptionResponse {
  invoiceIdentifier: string;
}

export interface ChangeAscriptionFormData {
  poNumber?: string;
  reference?: string;
  vatNumber?: string;
}

interface ChangeAscriptionAddress {
  firstName: string;
  lastName: string;
  streetName: string;
  houseNumber: string;
  additionalStreetInfo?: string;
  postalCode: string;
  city: string;
  countryCode: string;
  companyName?: string;
  phone?: string;
  email: string;
}

export interface ChangeAscriptionRequestPayload {
  invoiceNumber: string;
  languageCode: string;
  billing: ChangeAscriptionAddress;
  accountId?: string;
  poNumber?: string;
  reference?: string;
  vatNumber?: string;
}

class InvoiceService extends Api {
  getInvoices = async <T>({
    paymentStatusFilter,
    isLegacy = false,
    orderNumber,
    dates,
    chunkSize,
    continuationToken,
  }: GetInvoiceParams): Promise<T> => {
    const params = new URLSearchParams();

    if (paymentStatusFilter) {
      params.set('paymentState', paymentStatusFilter);
    }

    if (isLegacy) {
      params.set('legacy', '1');
    }

    if (orderNumber) {
      params.set('orderNumber', orderNumber);
    }

    if (continuationToken) {
      params.set('continuationToken', encodeURIComponent(continuationToken));
    }

    params.set('chunkSize', String(chunkSize));
    params.set('fromDate', dates.fromDate);
    params.set('toDate', dates.toDate);

    const { data } = await this.httpClient.get<T>(
      `/api/invoices/by/customer?${params}`,
      { blockAnonymous: true },
    );
    return Promise.resolve(data);
  };

  /**
   * @param {string} name
   * @returns {Promise}
   */
  async downloadInvoice(name: string): Promise<string> {
    try {
      const { data } = await this.httpClient.get(
        `/api/invoices/launchpad/${name}`,
      );
      return await Promise.resolve(data);
    } catch (error) {
      return Promise.resolve('');
    }
  }

  /**
   * Check if the invoice can be changed.
   * @param {string} invoiceNumber
   */
  async isChangeAscriptionInvoiceAllowed(
    invoiceNumber: string,
  ): Promise<boolean> {
    const { data } = await this.httpClient
      .get(`/api/invoices/launchpad/${invoiceNumber}/ascription`)
      .catch(() =>
        // in case of error, return false.
        Promise.resolve({ data: false }));
    return Promise.resolve(data);
  }

  /**
   * Get the change ascription form data
   * @param invoiceNumber
   */
  async getAscriptionFormData(invoiceNumber: string): Promise<ChangeAscriptionFormData> {
    const { data } = await this.httpClient
      .get(`api/invoices/launchpad/${invoiceNumber}/ascription/form-data`)
      .catch(() =>
        // in case of error, return empty object.
        Promise.resolve({ data: {} }));
    return Promise.resolve(data);
  }

  /**
   * Create a change ascription request
   * @param requestData
   */
  async createChangeAscriptionInvoice(
    requestData: ChangeAscriptionRequestPayload,
  ): Promise<CreateChangeAscriptionResponse> {
    const { data } = await this.httpClient.post(
      'api/invoicelines/change-ascription-request',
      requestData,
    );
    return Promise.resolve(data);
  }
}

export default InvoiceService;
