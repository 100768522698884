import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import type { AnalyticsSnippet } from '@segment/analytics-next';
import SegmentContext, { TraitStatus } from './SegmentContext';
import { ConsentData, getConsentData } from '../../helpers/CookiebotHelper';
import { decodePrintdealToken } from '../../helpers/auth';
import { selectors as authSelectors } from '../../store/slices/authSlice';
import { SentryHelper } from '../../helpers/SentryHelper';
import { getFromLocalStorage, LS_KEYS, setInLocalStorage } from '../../helpers/window';

function getSegmentationUrl() {
  const segmentationUrl = process.env.GATSBY_SEGMENTATION_URL;

  if (!segmentationUrl) {
    throw new Error('Env var not set: GATSBY_SEGMENTATION_URL');
  }

  return segmentationUrl;
}

declare global {
  interface Window {
    analytics: AnalyticsSnippet;
  }
}

function getAnonymousId() {
  return window.analytics.user().anonymousId();
}

function identifyLoggedInUser(userId: string, consentData: ConsentData) {
  /**
   * Clear traits before identifying user in order to prevent cached traits from being sent to segment
   * https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/identity/#clearing-traits
   */
  window.analytics.user().traits({});
  window.analytics.identify(userId, {}, { context: { consent: consentData } });
}

function setTraitsInLocalStorage(traits: Record<string, any>) {
  setInLocalStorage(LS_KEYS.SEGMENT_TRAITS, JSON.stringify(traits));
}

function getTraitsFromLocalStorage() {
  const traitsFromLocalStorage = getFromLocalStorage(LS_KEYS.SEGMENT_TRAITS);
  return traitsFromLocalStorage ? JSON.parse(traitsFromLocalStorage) : undefined;
}

interface GetTraitsResponse {
  traits: Record<string, any>;
  cursor: unknown;
}

interface Props {
  children?: React.ReactNode;
}

const SegmentProvider = ({ children }: Props) => {
  const isAuthLoading = useSelector(authSelectors.isAuthLoading);
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const token = useSelector(authSelectors.getAuthToken);

  const [consentData, setConsentData] = useState<ConsentData>();
  const [isSegmentAnalyticsReady, setIsSegmentAnalyticsReady] = useState<boolean>(false);
  const [isConsentReady, setIsConsentReady] = useState<boolean>(false);
  const [traits, setTraits] = useState<Record<string, any>>();
  const [traitStatus, setTraitStatus] = useState<TraitStatus>('idle');

  const segmentationUrl = getSegmentationUrl();

  const fetchAndSetTraits = useCallback(async () => {
    try {
      const getTraitsResponse = await axios.get<GetTraitsResponse>(
        `${segmentationUrl}/traits`,
        {
          params: isLoggedIn ? undefined : { anonymousId: getAnonymousId() },
          headers: {
            Authorization: token,
          },
        },
      );
      setTraits(getTraitsResponse.data.traits);
      setTraitsInLocalStorage(getTraitsResponse.data.traits);
      setTraitStatus('resolved');
    } catch (error: any) {
      // 404 means that the user has no traits yet
      if (error?.response?.status === 404) {
        setTraitStatus('resolved');
        return;
      }

      // 404s apparently happen a lot, so we need to filter them out of Sentry to not exceed
      // our limit of max messages per month.
      // The 404s can still be monitored in New Relic for further investigation.
      SentryHelper.exception({
        e: error,
        tags: {
          fileLocation: 'SegmentProvider',
        },
        fingerPrint: ['segment-provider-traits'],
      });
      setTraitStatus('error');
    }
  }, [segmentationUrl, isLoggedIn, token]);

  useEffect(() => {
    if (!isLoggedIn) {
      setTraitStatus('idle');
      setInLocalStorage(LS_KEYS.SEGMENT_TRAITS, '');
      return;
    }
    const localTraits = getTraitsFromLocalStorage();

    if (localTraits) {
      setTraits(localTraits);
      setTraitStatus('resolved');
    }

    try {
      if (!isAuthLoading && token && consentData?.statistics && consentData?.marketing && isSegmentAnalyticsReady) {
        if (!localTraits) setTraitStatus('loading');

        const userId = decodePrintdealToken(token).account?.id;
        if (isLoggedIn && userId) {
          identifyLoggedInUser(userId, consentData);
        }
        fetchAndSetTraits();
      }
    } catch (error) {
      SentryHelper.exception({
        e: error,
        tags: {
          fileLocation: 'SegmentProvider',
        },
        fingerPrint: ['segment-provider'],
      });
    }
  }, [isAuthLoading, isLoggedIn, consentData, isSegmentAnalyticsReady]);

  useEffect(() => {
    function onConsentReady() {
      setConsentData(getConsentData());
      setIsConsentReady(true);
    }

    function onSegmentAnalyticsReady() {
      setIsSegmentAnalyticsReady(true);
    }

    window.addEventListener('CookiebotOnConsentReady', onConsentReady);
    window.addEventListener('SegmentAnalyticsReady', onSegmentAnalyticsReady);

    return () => {
      window.removeEventListener('CookiebotOnConsentReady', onConsentReady);
      window.removeEventListener('SegmentAnalyticsReady', onSegmentAnalyticsReady);
    };
  }, []);

  const traitsContext = useMemo(() => ({
    traits,
    traitStatus,
    isSegmentAnalyticsReady,
    consentData,
    isConsentReady,
  }), [traits, traitStatus, isSegmentAnalyticsReady, consentData, isConsentReady]);

  return (
    <SegmentContext.Provider value={traitsContext}>
      {children}
    </SegmentContext.Provider>
  );
};

export default SegmentProvider;
