import type { Locale } from '../types/gatsby';

type LooseLocale = Locale | string;

type LocalizedNode<N> = N & {
  node_locale?: LooseLocale,
};

/**
 * Find a node by locale in nodes.
 */
export function findNodeByLocale<T>(nodes: LocalizedNode<T>[], locale: LooseLocale): T | undefined {
  return nodes.find(
    (node) => node?.node_locale === locale,
  );
}

export const getLocaleCodeFromLocale = (locale: LooseLocale) => new Intl.Locale(locale).language;
